import { useState } from "react";
import Link from "next/link";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { useRouter } from "next/router";
import validateContactForm from "../utils/validateContactForm";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactHelper = () => {
  const [form, setForm] = useState({
    email: "",
    message: "",
    name: "",
    phoneNumber: "",
    operation: "",
    value: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [errors, setErrros] = useState({});

  const router = useRouter(); //stop
  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-11382787557/3yTwCN3Uy-8YEOWb3rMq",
      event_callback: callback,
    });
    return false;
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { errors, valid } = validateContactForm(form);
    if (!valid) {
      setErrros(errors);
      setIsSubmitting(false);
    } else {
      fetch("/api/mail", {
        method: "post",
        body: JSON.stringify({
          recipient: "info@booklinik.com",
          templateId: "d-6b9ed961cfdc44228824603584a8b740",
          dynamicTemplateData: {
            email: form.email,
            name: form.name,
            phoneNumber: form.phoneNumber,
            datetime: moment(new Date()).format("LLLL"),
            message: form.message,
            path: router.asPath,
            operation: form.operation,
            value: value,
          },
        }),
      })
        .then(() => {
          gtag_report_conversion();
          setFormSent(true);

          fetch("/api/mail", {
            method: "post",
            body: JSON.stringify({
              recipient: form.email,
              templateId: "d-57cbc54b5ac345beb1bfc6509381ccee",
              dynamicTemplateData: {
                email: form.email,
                name: form.name,
                phoneNumber: form.phoneNumber,
                datetime: moment(new Date()).format("LLLL"),
                message: form.message,
                operation: form.operation,
                value: value,
              },
            }),
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          setIsSubmitting(false);
          setErrros({});
        });
    }
  };

  const handleFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.name === "phoneNumber"
          ? e.target.value
              .replace(/[^0-9.]/g, "")
              .replace(/(\..*?)\..*/g, "$1")
              .trim()
          : e.target.value,
    });
  };

  const handlePhoneNumber = (phone) => {
    setForm({
      ...form,
      phoneNumber: `+${phone}`,
    });
  };

  const getInitialState = () => {
    const value = "";
    return value;
  };

  const [value, setValue] = useState(getInitialState);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div id="contactform" className="py-40">
      <div className="mx-4 xl:mx-auto max-w-7xl py-14 my-10 rounded-xl bg-shamrock grid gric-cols-1 lg:grid-cols-2 gap-10 px-10 text-white placeholder-white">
        <div className="text-white">
          <p className="uppercase text-sm mb-2">Bir sorunuz mu var?</p>
          <h2 className="text-4xl mb-2">
            Doktorlarımız tarafından ÜCRETSİZ TIBBİ DANIŞMANLIK.
          </h2>
          <h2 className="text-2xl mb-2 mt-4">
            24 saat içinde sizinle iletişime geçeceğimiz formu doldurun.
          </h2>
          <p className="mt-4 mb-2">Telefonla</p>
          <link href="tel:0186653500">
            <a className="hover:underline text-xl font-bold flex items-center">
              01 86 65 35 00
            </a>
          
        </div>
        <form onSubmit="{handleFormSubmit}">
          <div className="space-y-6">
            {!formSent ? (
              <>
                <div>
                  <p className="uppercase text-sm mb-2">Ad</p>
                  <input type="text" className="{`w-full" bg-transparent="" border-b="" outline-none="" placeholder-white="" ${="" errors="" &&="" errors.name="" ?="" "border-red-600="" "="" :="" "border-white"="" }="" p-3`}="" placeholder="Ad" name="name" value="{form.name}" onChange="{handleFormChange}">
                  {hatalar & errors.name ? (
                    <span className="text-red-600 text-sm mt-3">
                      {errors.name}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="{`${" errors="" &&="" errors.phoneNumber="" ?="" "error-input"="" :="" ""="" }`}="">
                  <p className="uppercase text-sm mb-2">Telefon numarası</p>
                  <phoneinput country="{&quot;fr&quot;}" value="{form.phoneNumber}" onChange="{(phone)" ==""> handlePhoneNumber(phone)}
                  />
                  {errors && errors.phoneNumber ? (
                    <span className="text-red-600 text-sm mt-3">
                      {errors.phoneNumber}
                    </span>
                  ) : (
                    ""
                  )}
                </phoneinput></div>

                <div>
                  <p className="uppercase text-sm mb-2">E-posta adresiniz</p>
                  <input type="email" className="{`w-full" bg-transparent="" border-b="" outline-none="" placeholder-white="" ${="" errors="" &&="" errors.email="" ?="" "border-red-600="" "="" :="" "border-white"="" }="" p-3`}="" placeholder="E-posta" name="email" value="{form.email}" onChange="{handleFormChange}">
                  {hatalar & errors.email ? (
                    <span className="text-red-600 text-sm mt-3">
                      {errors.email}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <label className="uppercase text-sm mb-2">
                    Operasyonunuzu seçin
                    <select value="{value}" onChange="{handleChange}" className="w-full bg-transparent border-b outline-none placeholder-white  text-xl hover:text-shamrock block hover:bg-gray-100 w-full p-5 py-3 cursor-pointer">
                      <option value="Greffe de cheveux">
                        Saç ekimi
                      </option>
                      <option value="Chirurgie mammaire">
                        Meme cerrahisi
                      </option>
                      <option value="Chirurgie du nez">Burun ameliyatı</option>
                      <option value="Chirurgie des fesses">
                        Kalça ameliyatı
                      </option>
                      <option value="Chirurgie du visage">
                        Yüz cerrahisi
                      </option>
                      <option value="Chirurgie du corps">
                        Vücut cerrahisi
                      </option>
                      <option value="Medecine esthétique">
                        Estetik tıp
                      </option>
                      <option value="Chirurgie de l oeil">
                        Göz cerrahisi
                      </option>
                      <option value="Chirurgie dentaires">
                        Diş cerrahisi
                      </option>
                      <option value="Autre">Başka </option>
                    </select>
                    {değer == "Diğer" ? (
                      <span className=" text-sm mt-3">
                        <div>
                          <input type="text" className="w-full bg-transparent border-b outline-none placeholder-white py-5" placeholder="Belirtmek..." name="operation" value="{form.operation}" onChange="{handleFormChange}">
                        </div>
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>

                <div>
                  <p className="uppercase text-sm mb-2">Mesajınız</p>
                  <textarea className="{`w-full" h-24="" bg-white="" bg-opacity-10="" border-b="" outline-none="" placeholder-whiteborder-white="" p-3`}="" placeholder="Hakkında bir sorum var ..." value="{form.message}" name="message" onChange="{handleFormChange}"></textarea>
                </div>
                <div className="w-full">
                  <button type="submit" className="float-right rounded bg-white bg-opacity-10 p-3 transition hover:bg-opacity-100 hover:text-shamrock" disabled="{isSubmitting}">
                    Gönder
                  </button>
                </div>
              </>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <p className="text-center">
                  Mesajınız gönderildi. Sizinle iletişime geçeceğiz
                  mümkün olan en kısa sürede.
                </p>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

varsayılan ContactHelper'ı dışa aktarma;
